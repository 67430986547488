import * as React from "react";
import "@src/scss/App.scss";
import Layout from "@components/layout";
import Seo from "@components/seo";
import ButtonLink from '@components/ButtonLink';
import ModuleSection from '@components/modules/moduleWrapper';
import BannerRender from "@components/modules/Banner/BannerRender";
import { graphql } from "gatsby"

const breadcrumbs = [
    { label: "Sitemap", url: `/sitemap` }
]
const page = {
    HeaderTemplate: "Terms",
    BannerImage: [{
        id: "afsdf2e423e",
        ItemTitle: "Sitemap",
        ItemText: null,
        hideBreadcrumbs: false,
        Buttons: []
    }]
}

const SitemapPage = ({ data: { glstrapi: { menus } } }) => {
    const createDataTree = dataset => {
        let hashTable = Object.create(null)
        // add a childNodes array to each node
        dataset.forEach( aData => hashTable[aData.id] = { ...aData, childNodes : [] } )
        
        let dataTree = []
        // loop over nodes, the child node has to have the Parent id
        dataset.forEach( aData => {
            // push the child node under the parent node
            if( aData.Parent ) {
                hashTable[`${aData.Parent.id}`].childNodes.push(hashTable[aData.id])
            } else {
                // this is the normal parent node
                dataTree.push(hashTable[aData.id])
            }
        })
        return dataTree
    }
    const menuTree = createDataTree(menus);

    return(
        <Layout bodyClass="noBannerImage list-property-page" headerClass="header-dark">
            <Seo title="Sitemap" />
            <BannerRender
                page={page}
                breadcrumbs={breadcrumbs}
            />
            <ModuleSection
                sectionClass={`position-relative z-index-5`}
            >
                <div className="sitemap-wrapper">
                    {menuTree?.map((menu) => {
                        return(
                            <ul key={menu.id} className="parent-link">
                                <li><ButtonLink {...menu.Link} variant="link" key={menu?.id} /></li>
                                {menu.childNodes.length > 0 &&
                                    <ul>
                                        {menu.childNodes.map((child) => {
                                            // if page is a child the link has to have Parent url
                                            let linkUrl = "";
                                            if (child.Parent?.Slug && !child.Link?.manualLink) {
                                                linkUrl += `/${child.Parent.Slug}`;
                                            }
                                            
                                            // else if (child.Parent?.manualLink) {
                                            //     linkUrl += `${child.Parent.manualLink}`;
                                            // }
                                            if (child.Link?.page?.Slug) {
                                                linkUrl += `/${child.Link.page.Slug}`;
                                            } else if (child.Link?.manualLink) {
                                                linkUrl += `${child.Link.manualLink}`;
                                            }
                                            let childLink = { // after setting up parent - child url reconstruct the button link
                                                ...child,
                                                Link: {
                                                    ...child.Link,
                                                    page: null,
                                                    manualLink: linkUrl
                                                }
                                            };

                                            return(
                                                <li key={child.id} className="child-link-one">
                                                        {child.childNodes.length > 0 ?
                                                            <>
                                                                <ButtonLink {...childLink.Link} variant="link" key={child.id} />
                                                                <ul>
                                                                    {child.childNodes.map((child) => {
                                                                        return(
                                                                            <li eventKey={child.id} key={child.id} className="child-link-two">
                                                                                {child.length > 0 ?
                                                                                    <ButtonLink {...childLink.Link} variant="link" key={child?.id} />
                                                                                :
                                                                                    <ButtonLink {...child.Link} variant="link" key={child?.id} />
                                                                                }
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </>
                                                        :
                                                            <ButtonLink {...childLink.Link} variant="link" key={child?.id} />
                                                        }
                                                </li>
                                            )
                                        })}
                                    </ul>
                                }
                            </ul>
                        )
                    })}
                </div>
            </  ModuleSection>
        </Layout>
    )
}

export default SitemapPage

export const query = graphql`
query sitemaQuery {
    glstrapi {
        menus(publicationState: LIVE, sort: "Sort:ASC") {
            id
            Sort
            Slug
            Label
            Parent {
                id
                Slug
                Label
                Parent {
                    id
                    Label
                    Slug                   
                }
            }
            Link {
                id
                manualLink
                label
                icon
                btnClass
                page {
                    Slug
                    id
                    Parent {
                        id
                        Slug
                    }
                    Parent2 {
                        id
                        Slug
                    }
                    Parent3 {
                        Slug
                        id
                    }
                }
            }
        }
    }
}
`